import React, { useState } from 'react';
import DisplayDrawer from '../common/DisplayDrawer';
import styled from 'styled-components';
import { zIndexValues } from '../../style';
import DrawerInput from '../common/DrawerInput';
import CustomCTA from '../CustomCTA';
import { isValidEmail } from '../../utils/helper';
import { companyTypeList, DELAY_TIME } from '../../constants/employer';

const ContentSection = styled.div`
  width: 100%;
  margin: 20px 20px 0 20px;
`;

const HorizontalContainer = styled.div`
  display: flex;
`;

const StyledHeader = styled.p`
  font-size: ${(props) => props?.$fontSize};
  line-height: ${(props) => props?.$lineHeight};
  font-weight: ${(props) => props?.$fontWeight};
  color: ${(props) => props?.$color};
  margin: ${(props) => props?.$margin};
  width: ${(props) => (props?.$width ? props?.$width : '100%')};
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.$justifyContent ? props.$justifyContent : 'space-between'};
  gap: ${(props) => (props.$gap ? props.$gap : '10px')};
  opacity: ${(props) => (props.$opacity ? props.$opacity : '1')};
`;

const CreateAccDrawerNext = ({
  open,
  toggleDrawer,
  handleCreateNewAccount = () => {},
  isEdit = false,
  clearFields = () => {},
  createAccObj,
  createAccErr,
  setCreateAccErr,
  setCreateAccObj,
  setIsCalculateEarningsVisible,
}) => {
  const [categoryOpen, setCategoryOPen] = useState(false);
  const [isCreateCalled, setIsCreateCalled] = useState(false);

  const handleCloseDrawer = () => {
    toggleDrawer(false);

    clearFields();
  };

  const handleFieldUpdate = (event, fieldName, subFieldName) => {
    switch (fieldName) {
      case 'email':
        setCreateAccObj({ ...createAccObj, email: event.target.value });
        break;
    }
  };

  const headerContent = () => {
    return (
      <StyledHeader
        $fontSize={'24px'}
        $lineHeight={'36px'}
        $fontWeight={'600'}
        $color={'#000'}
      >
        New Account
      </StyledHeader>
    );
  };

  const handleSaveClick = () => {
    const newErrors = {
      email: !isValidEmail(createAccObj?.email),
    };

    setCreateAccErr(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error);

    if (!hasErrors) {
      handleCreateNewAccount();
      // handleCloseDrawer();
    }
  };

  const handleCategorySelect = (cat) => {
    setCreateAccObj({ ...createAccObj, companyType: cat });
    setCategoryOPen(!categoryOpen);
  };

  const handleThrottledCreate = () => {
    if (isCreateCalled) return;

    handleSaveClick();
    setIsCreateCalled(true);

    setTimeout(() => {
      setIsCreateCalled(false);
    }, DELAY_TIME);
  };

  const footerContent = () => {
    return (
      <CustomCTA
        onClick={handleThrottledCreate}
        title={'Create Account'}
        color={'#FFF'}
        bgColor={'#141482'}
        border={'1px solid #CDD4DF'}
        disabled={createAccObj?.email == ''}
        isLoading={isCreateCalled}
      />
    );
  };

  return (
    <DisplayDrawer
      open={open}
      handleCloseDrawer={handleCloseDrawer}
      zIndex={zIndexValues.ADD_COURSE_DRAWER}
      headerContent={headerContent}
      footerContent={footerContent}
    >
      <HorizontalContainer>
        <ContentSection>
          <DrawerInput
            fieldType={'input'}
            fieldHeader={'Email ID'}
            fieldError={createAccErr?.email}
            fieldPlaceholder={'Enter email id'}
            fieldValue={createAccObj?.email}
            handleFieldChange={(e) => handleFieldUpdate(e, 'email')}
            isManadatory={true}
            errorText={'* Enter a valid Email'}
          />
        </ContentSection>
        <ContentSection>
          <DrawerInput
            isManadatory={false}
            fieldType={'dropdown'}
            fieldHeader={'Company Type'}
            fieldError={createAccErr?.companyType}
            fieldValue={createAccObj?.companyType || 'Select company type'}
            handleDropDownSelect={handleCategorySelect}
            dropDownOpen={categoryOpen}
            handleDropDownOpen={setCategoryOPen}
            dropDownList={companyTypeList}
          />
        </ContentSection>
      </HorizontalContainer>
    </DisplayDrawer>
  );
};

export default CreateAccDrawerNext;
