import React, { useEffect, useState, lazy, Suspense } from 'react';
import styled from 'styled-components';
import ICONS from '../assets/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
const CustomerDetailsTabs = lazy(
  () => import('../components/customerDetails/CustomerDetailsTabs'),
);
import { useGetCustomerDetails } from '../apis/queryHooks';
const WorkExp = lazy(() => import('../components/workexp/WorkExp'));
const CustomerPageHeader = lazy(
  () => import('../components/customerDetails/CustomerPageHeader'),
);
const VerificationPage = lazy(
  () => import('../components/verification/VerificationDetails'),
);

const Wrapper = styled.div`
  margin: 61px 0 0 265px;
  min-height: calc(100vh - 3.5rem);
  background-color: #f4f6fa;
  padding: 16px 40px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  display: inline-flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
`;

const Img = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  cursor: pointer;
`;

const CustomerDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [showVerificationPage, setShowVerificationPage] = useState(false);
  const [showWorkExperiencePage, setShowWorkExperiencePage] = useState(false);
  const [pageRoute, setPageRoute] = useState('');
  const [workExpIndex, setWorkExpIndex] = useState(null);
  const customerId = location.pathname.substring(11, location.pathname.length);

  const {
    data: customerData,
    isLoading: isCustomerDataLoading,
    isFetching: isCustomerDataFetching,
    isError: isCustomerDataError,
    error: customerDataError,
    refetch: refetchCustomerData,
  } = useGetCustomerDetails(customerId);

  useEffect(() => {
    if (isCustomerDataError) {
      enqueueSnackbar('Error while fetching customer details', {
        variant: 'error',
      });
    }
  }, [isCustomerDataError]);

  const handleLeftArrow = () => {
    navigate(-1);
  };

  if (showVerificationPage) {
    return (
      <Suspense fallback={<div></div>}>
        <VerificationPage
          customerData={customerData}
          isCustomerDataFetching={isCustomerDataFetching}
          customerDataError={customerDataError}
          isCustomerDataError={isCustomerDataError}
          refetchCustomerData={refetchCustomerData}
          setShowVerificationPage={setShowVerificationPage}
          pageRoute={pageRoute}
          setPageRoute={setPageRoute}
        />
      </Suspense>
    );
  }

  if (showWorkExperiencePage) {
    return (
      <Suspense fallback={<div></div>}>
        <WorkExp
          customerData={customerData}
          employments={customerData?.employments}
          workExpIndex={workExpIndex}
          isCustomerDataFetching={isCustomerDataFetching}
          customerDataError={customerDataError}
          isCustomerDataError={isCustomerDataError}
          refetchCustomerData={refetchCustomerData}
          setShowWorkExperiencePage={setShowWorkExperiencePage}
        />
      </Suspense>
    );
  }
  return (
    <Wrapper>
      <Header>
        <Left onClick={() => handleLeftArrow()}>
          <Img
            src={ICONS.LEFT_ARROW_BLACK}
            alt="leftArrowBlack"
            width={'24px'}
            height={'24px'}
          />
        </Left>
      </Header>
      <Suspense fallback={<div></div>}>
        <CustomerPageHeader
          heading={customerData?.name ? customerData?.name : '-----'}
          subHeading={`Customer ID: ${customerData?._id ? customerData?._id : '-----'}`}
        />
      </Suspense>
      <Suspense fallback={<div></div>}>
        <CustomerDetailsTabs
          userData={customerData}
          userDataLoading={isCustomerDataLoading}
          isUserDataError={isCustomerDataError}
          setPageRoute={setPageRoute}
          setWorkExpIndex={setWorkExpIndex}
          setShowVerificationPage={setShowVerificationPage}
          setShowWorkExperiencePage={setShowWorkExperiencePage}
          refetchCustomerData={refetchCustomerData}
        />
      </Suspense>
    </Wrapper>
  );
};

export default CustomerDetails;
