import { Tooltip, tooltipClasses } from '@mui/material';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#000',
    width: '40px',
    height: '20px',
    top: '-10px !important',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000',
    padding: '8px 12px',
    maxWidth: '300px',
    marginLeft: '20px',
    left: '20px!important',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
  },
}));
CustomTooltip.propTypes = {
  className: PropTypes.string,
  arrow: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default CustomTooltip;
