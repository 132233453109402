import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.main`
  background-color: #f4f6fa;
  position: relative;
  width: 100%;
  // height: 100vh;
`;

const Layout = () => {
  return (
    <Wrapper>
      <Header />
      <Sidebar />
      <Outlet />
    </Wrapper>
  );
};

export default Layout;
