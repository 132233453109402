export const ENDPOINTS = Object.freeze({
  getAllCourses: '/lms/api/v1/curriculum/course/all',
  postAddCourse: '/lms/api/v1/curriculum/course',
  courseDetails: (id) => `/lms/api/v1/curriculum/course/${id}`,
  uploadcsv: (id) => `/lms/api/v1/curriculum/course/uploadcsv/${id}`,
  courseAssessment: '/lms/api/v1/curriculum/courseAssessment',
  editCourseModule: (id) => `/lms/api/v1/curriculum/courseModule/${id}`,
  editCourseSubModule: (id) => `/lms/api/v1/curriculum/courseSubModule/${id}`,
  editCourseAssessment: (id) => `/lms/api/v1/curriculum/courseAssessment/${id}`,
  getAllPayments: `/payment/api/v1`,
  getRefundPayments: `/order/api/v1/refund`,
  refundPaymentDetails: (id) => `/order/api/v1/refund/${id}`,
  paymentDetails: (id) => `/payment/api/v1/${id}`,
  getAllOrders: `/order/api/v1`,
  getOrderDetails: (id) => `/order/api/v1/internal/${id}`,
  getCustomerPayments: (id) => `/payment/api/v1/user/${id}`,
  getCustomerOrders: (id) => `/order/api/v1/user/${id}`,
  getCustomerCourses: (id) =>
    `/customerJourney/api/v1/journey/dash/myTrainings/${id}`,
  getAllCustomers: '/customer/api/v1/',
  customerDetails: (id) => `/customer/api/v1/${id}`,
  putCustomerDetails: `/customer/api/v1/update`,
  postIdentity: `/identity/api/v1/guest`,
  postVerifyPassword: `/user/api/V1/login/verify/password`,
  uploadToS3: (filePath, type) =>
    `/utils/api/v1/aws/uploadFile?filePath=${filePath}&type=${type}`,
  postEditPsychWeightage:
    '/lms/api/v1/curriculum/course/psychometric-trait-weightage',
  getGlobalData: `/lms/api/v1/curriculum/course/data`,
  getUserDetails: (userId) => `/user/api/v1?id=${userId}`,
  putResetPassword: '/user/api/v1/password',
  postRemarks: `/customer/api/v1/trueIdRemark/create/remark`,
  putUpdateTrueId: `/customer/api/v1/trueId`,
  postSendSms: `/customer/api/v1/trueId/verification/sms`,
  putUpdateCustomer: `/customer/api/v1/update/`,
  getReferrerDetails: (id) =>
    `/referral/api/v1/referral/details?referrerUserId=${id}`,
  getReferralHistory: (id, userType) =>
    `/referral/api/v1/referral/orderHistory?referrerUserId=${id}&userType=${userType}`,
  getAllUsers: `/user/api/v1/getAll`,
  getAllRoles: `/authorization/api/v1/role/getAll`,
  getAllPermissions: `/authorization/api/v1/permission/getAll`,
  postUserRole: `/authorization/api/v1/role`,
  putOrDeleteUserRole: (id) => `/authorization/api/v1/role/${id}`,
  postAddUser: `/user/api/v1/create/ops`,
  putUpdateUser: (id) => `/user/api/v1?id=${id}`,
  deleteUser: (id) => `/user/api/v1/${id}`,
  postUserPermission: `/authorization/api/v1/permission`,
  putOrDeleteUserPermission: (id) => `/authorization/api/v1/permission/${id}`,
  getAllTests: `/test/api/v1/tests`,
  getTestDetails: (id) => `/test/api/v1/tests/${id}`,
  uploadTestCsv: (id) => `/test/api/v1/tests/uploadcsv/${id}`,
  postAddTest: `/test/api/v1/tests/create`,
  putOrDeleteTest: (id) => `/test/api/v1/tests/${id}`,
  putUpdateTestAssessment: `/test/api/v1/tests/updateAssessment`,
  getTestCategories: `/test/api/v1/tests/getCategories`,
  getEmpReferrerDetails: (id) =>
    `/referral/api/v1/referral/refereeDetails?parentReferrerUserId=${id}`,
  getEmpReferrerEarnDetails: (id, type) =>
    `/referral/api/v1/referral/refereeDetails?parentReferrerUserId=${id}&expandLevel1Details=${type}`,
  getEmpCandidateReferrerEarnDetails: (id, candidateId, type) =>
    `/referral/api/v1/referral/refereeDetails?parentReferrerUserId=${id}&targetChildReferrerUserId=${candidateId}&expandLevel1Details=${type}`,
  getAllPayouts: `/staffingAgency/api/v1/payoutRequests`,
  changePayoutStatus: (id) => `/staffingAgency/api/v1/${id}/changePayoutStatus`,
  getEmployerDetails: (id) => `/staffingAgency/api/v1/${id}`,
  employerDocPostRemarks: `/staffingAgency/api/v1/staffingAgencyRemark/create/remark`,
  putUpdateEmployerStatus: (id) => `/staffingAgency/api/v1/?id=${id}`,
  getAllEmployer: `/staffingAgency/api/v1`,
  getAllStaff: `/staffingAgency/api/v1/staffCustomers`,
  getEarnings: `/referral/api/v1/earning`,
  postInitiateRefund: (id) => `/order/api/v1/refund/${id}`,
});
