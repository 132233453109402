export const PAGE_PERMISSIONS = {
  VIEW_COURSE: 'OPS_VIEW_COURSES',
  VIEW_PAYMENTS: 'OPS_VIEW_PAYMENTS',
  VIEW_ORDERS: 'OPS_VIEW_ORDERS',
  VIEW_CUSTOMER: 'OPS_VIEW_CUSTOMER_LIST',
  VIEW_COURSE_DETAILS: 'OPS_VIEW_COURSES',
  VIEW_CUSTOMER_DETAILS: 'OPS_VIEW_CUSTOMER_LIST',
  VIEW_ORDER_DETAILS: 'OPS_VIEW_ORDERS',
  VIEW_PAYMENT_DETAILS: 'OPS_VIEW_PAYMENTS',
  VIEW_ROLES_MANAGEMENT: 'OPS_VIEW_USER_MANAGEMENT',
  VIEW_TESTS: 'OPS_VIEW_COURSES',
  VIEW_EMPLOYERS: 'OPS_VIEW_ORDERS',
  VIEW_EMPLOYER_DETAILS: 'OPS_VIEW_ORDERS',
  VIEW_PAYOUT_REQUESTS: 'OPS_VIEW_PAYMENTS',
};
export const COURSE_MODULE_PERMISSIONS = {
  VIEW_COURSE: 'OPS_VIEW_COURSES',
  ADD_COURSE: 'OPS_EDIT_COURSES',
  DOWNLOAD_TEMPLATE: 'OPS_EDIT_COURSES',
  DELETE_COURSE: 'OPS_EDIT_COURSES',
  EDIT_COURSE: 'OPS_EDIT_COURSES',
  VIEW_COURSE_DETAILS: 'OPS_VIEW_COURSES',
  EDIT_COURSE_DETAILS: 'OPS_EDIT_COURSES',
};
export const ORDER_PERMISSIONS = {
  DOWNLOAD_ORDERS: 'OPS_DOWNLOAD_ORDERS',
};
export const PAYMENT_PERMISSIONS = {
  DOWNLOAD_PAYMENTS: 'OPS_DOWNLOAD_PAYMENTS',
  VIEW_PAYMENTS: 'OPS_VIEW_INCOMING_PAYMENTS',
  VIEW_REFUNDS: 'OPS_VIEW_REFUND_PAYMENTS',
};
export const CUSTOMER_DETAILS_PERMISSIONS = {
  VIEW_BASIC_DETAILS: 'OPS_VIEW_TRUE_ID',
  EDIT_BASIC_DETAILS: 'OPS_EDIT_BASIC_DETAILS',
  VIEW_VERIFICATION_DETAILS: 'OPS_VIEW_VERIFICATION_DETAILS',
  EDIT_VERIFICATION_DETAILS: 'OPS_EDIT_VERIFICATION_DETAILS',
  ADD_WORKEX: 'OPS_EDIT_WORK_EXPERIENCE',
  VIEW_WORKEX_DETAILS: 'OPS_VIEW_WORK_EXPERIENCE',
  EDIT_WORKEX_DETAILS: 'OPS_EDIT_WORK_EXPERIENCE',
  CHANGE_WORKEX_VERIFICATION_STATUS: 'OPS_EDIT_WORK_EXPERIENCE',
  DELETE_WORKEX_DETAILS: 'OPS_EDIT_WORK_EXPERIENCE',
  ADD_WORKEX_REMARKS: 'OPS_EDIT_WORK_EXPERIENCE',
  ADD_VERIFICATION_REMARKS: 'OPS_EDIT_VERIFICATION_DETAILS',
};
export const CUSTOMER_PERMISSIONS = {
  VIEW_PAYMENT_DETAILS: 'OPS_VIEW_CUSTOMER_PAYMENTS',
  VIEW_ORDER_DETAILS: 'OPS_VIEW_CUSTOMER_ORDERS',
  VIEW_COURSES_DETAILS: 'OPS_VIEW_CUSTOMER_COURSES',
  VIEW_REFERAL_DETAILS: 'OPS_VIEW_REFERRALS',
  DOWNLOAD_CUSTOMERS: 'OPS_DOWNLOAD_CUSTOMER_LIST',
};

export const USER_MANAGEMENT_PERMISSIONS = {
  VIEW_USER_MANAGEMENT: 'OPS_VIEW_USER_MANAGEMENT',
  VIEW_ROLE_MANAGEMENT: 'OPS_VIEW_ROLE_MANAGEMENT',
  EDIT_USER_MANAGEMENT: 'OPS_EDIT_USER_MANAGEMENT',
};

export const TEST_MODULE_PERMISSIONS = {
  VIEW_TEST: 'OPS_VIEW_COURSES',
  ADD_TEST: 'OPS_EDIT_COURSES',
  DOWNLOAD_TEMPLATE: 'OPS_EDIT_COURSES',
  DELETE_TEST: 'OPS_EDIT_COURSES',
  EDIT_TEST: 'OPS_EDIT_COURSES',
  VIEW_TEST_DETAILS: 'OPS_VIEW_COURSES',
  EDIT_TEST_DETAILS: 'OPS_EDIT_COURSES',
};

export const EMPLOYER_MODULE_PERMISSIONS = {
  VIEW_PROFILE_DETALILS: 'OPS_VIEW_ORDERS',
  VIEW_REFERRAL_DETAILS: 'OPS_VIEW_ORDERS',
  VIEW_STAFF_DETAILS: 'OPS_VIEW_ORDERS',
  VIEW_CANDIDATE_REFERRAL_DETAILS: 'OPS_VIEW_ORDERS',
};

export const PAYOUT_PERMISSIONS = {
  DOWNLOAD_PAYOUTS: 'OPS_DOWNLOAD_PAYMENTS',
  VIEW_PAYOUT_DETAILS: 'OPS_VIEW_INCOMING_PAYMENTS',
};
