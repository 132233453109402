import React, { Suspense, lazy, useState, useContext, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import CustomCTA from '../components/CustomCTA';
import styleComponents from '../style/pageStyle';
import { RUPEE_SYMBOL } from '../constants/details';
import ICONS from '../assets/icons';
import PaymentsTabs from '../components/payments/PaymentsTabs';
import { PaymentContext } from '../context/PaymentContext';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../apis/queryKeys';
import { convertToCSV, downloadCSV } from '../utils/helper';
import { PAYMENT_PERMISSIONS } from '../constants/permissions';
import usePermission from '../hooks/usePermission';

const SearchFilter = lazy(() => import('../components/SearchFilter'));

const DateFilter = lazy(() => import('../components/DateFilter'));
const FilterPaymentsDrawer = lazy(
  () => import('../components/payments/FilterPaymentsDrawer'),
);
const {
  Wrapper,
  Top,
  Bottom,
  HeaderWrap,
  Header,
  HeaderTitle,
  HeaderDesc,
  SearchDiv,
  SearchBox,
  AnimatedBox,
} = styleComponents();

const Payments = () => {
  const queryClient = useQueryClient();
  const { hasPermission } = usePermission();
  const [totalItems, setTotalItems] = useState(0);
  const [searchId, setSearchID] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [totalFiltersCount, setTotalFiltersCount] = useState(0);
  const [filterKeys, setFilterKeys] = useState('');
  const [paymentCheckboxes, setPaymentCheckBoxes] = useState([
    { key: 'COMPLETED', value: 'Completed', checked: false },
    { key: 'PENDING', value: 'Pending', checked: false },
    { key: 'FAILED', value: 'Failed', checked: false },
  ]);
  const {
    getPaymentsDataByType,
    paymentType,
    incomingPaymentDetails: { allPaymentsData, isGetAllPaymentsLoading },
    refundPaymentDetails: { refundPaymentsData, isGetRefundPaymentsLoading },
  } = useContext(PaymentContext);

  const paymentDataByType = () => {
    switch (paymentType) {
      case 'incoming':
        getPaymentsDataByType('incoming', {
          searchId,
          fromDate,
          toDate,
          filterKeys,
        });
        break;
      case 'refund':
        getPaymentsDataByType('refund', {
          searchId,
          fromDate,
          toDate,
          filterKeys,
        });
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    paymentDataByType();
  }, [filterKeys]);

  const handleSearchById = () => {
    queryClient.resetQueries(QUERY_KEYS?.GET_ALL_PAYMENTS);
    paymentDataByType();
  };

  const handleEnterButton = (e) => {
    if (e.key === 'Enter') {
      handleSearchById();
    }
  };

  const clearFilters = () => {
    setPaymentCheckBoxes((prevCheckboxes) => {
      return prevCheckboxes.map((checkbox) => {
        return { ...checkbox, checked: false };
      });
    });
  };

  const searchArr = [
    {
      id: 1,
      placeHolder:
        paymentType === 'refund'
          ? 'Search by Refund/Order/Customer ID'
          : 'Search by Payment/Order/Customer ID',
      width: '300px',
      setInput: setSearchID,
      enteredInput: searchId,
    },
  ];

  const handleDownload = () => {
    let formattedData = {};
    let fileName;
    switch (paymentType) {
      case 'incoming':
        formattedData = allPaymentsData?.payment?.map((item) => ({
          'Payment ID': item?._id,
          'Order ID': item?.orderId,
          'Payment Date': item?.paymentDate,
          'Customer ID': item?.typeId,
          'User Type': item?.userType,
          'Payment Method': item?.paymentApp,
          'Total Amount': `Rs. ${item?.amount}`,
          'Payment Status': item?.paymentStatus,
        }));
        fileName = 'payments.csv';
        break;
      case 'refund':
        formattedData = refundPaymentsData?.refunds?.map((item) => ({
          'Refund ID': item?._id,
          'Order ID': item?.orderId,
          'Customer ID': item?.typeId,
          'Refund Date': item?.refundDate,
          'Refund Status': item?.status,
          'Refund Amount': `Rs. ${item?.refundAmount}`,
        }));
        fileName = 'refunds.csv';
        break;
    }

    const csv = convertToCSV(formattedData);
    downloadCSV(csv, fileName);
  };

  return (
    <Wrapper>
      <Top>
        <HeaderWrap>
          <Header>
            <HeaderTitle>All Payments</HeaderTitle>
            <HeaderDesc>{`Total Payments: ${totalItems}`}</HeaderDesc>
          </Header>
          <CustomCTA
            onClick={handleDownload}
            title={'Download Payments'}
            showIcon={false}
            color={'#FFF'}
            bgColor={'#141482'}
            border={'1px solid #CDD4DF'}
            isPermitted={hasPermission(PAYMENT_PERMISSIONS?.DOWNLOAD_PAYMENTS)}
          />
        </HeaderWrap>
        <Suspense fallback={<div></div>}>
          <SearchDiv>
            <SearchBox>
              <SearchFilter
                searchArr={searchArr}
                isFilter={false}
                onKeyPress={handleEnterButton}
              />
              <DateFilter
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
              />
              <CustomCTA
                onClick={handleSearchById}
                title={'Search'}
                showIcon={false}
                color={'#FFF'}
                bgColor={'#141482'}
                isLoading={
                  isGetAllPaymentsLoading || isGetRefundPaymentsLoading
                }
                border={'1px solid #CDD4DF'}
              />
            </SearchBox>
            <CustomCTA
              onClick={() => setOpenFilterDrawer(true)}
              url={ICONS.FILTER}
              title={`Filter (${totalFiltersCount ?? ''})`}
              showIcon={true}
              bgColor={'#677995'}
              color={'#FFF'}
              border={'none'}
              fontSize={'12px'}
              gap={'12px'}
            />
          </SearchDiv>
        </Suspense>

        <Suspense
          fallback={
            <AnimatedBox>
              {[...Array(5)].map((_, idx) => (
                <Skeleton animation="wave" height={70} key={idx} />
              ))}
            </AnimatedBox>
          }
        >
          <PaymentsTabs
            searchId={searchId}
            setSearchID={setSearchID}
            setFromDate={setFromDate}
            setToDate={setToDate}
            setTotalItems={setTotalItems}
            filterKeys={filterKeys}
            setFilterKeys={setFilterKeys}
            setTotalFiltersCount={setTotalFiltersCount}
            fromDate={fromDate}
            toDate={toDate}
            totalItems={totalItems}
            setPaymentCheckBoxes={setPaymentCheckBoxes}
            clearFilters={clearFilters}
          />
          <FilterPaymentsDrawer
            open={openFilterDrawer}
            toggleDrawer={setOpenFilterDrawer}
            totalFiltersCount={totalFiltersCount}
            setTotalFiltersCount={setTotalFiltersCount}
            setFilterKeys={setFilterKeys}
            paymentCheckboxes={paymentCheckboxes}
            setPaymentCheckBoxes={setPaymentCheckBoxes}
            clearFilters={clearFilters}
          />
        </Suspense>
      </Top>
    </Wrapper>
  );
};

export default Payments;
