import React, { useContext, useEffect } from 'react';
import DisplayTable from '../DisplayTable';
import { useNavigate } from 'react-router-dom';
import { PaymentContext } from '../../context/PaymentContext';
import { useSnackbar } from 'notistack';

const PaymentsRefundOrders = ({
  searchId,
  fromDate,
  toDate,
  filterKeys,
  currentPage,
  itemsPerPage,
  arrBtn,
  actionIndex,
  setActionIndex,
  actionOpen,
  setActionOpen,
  setUserType,
  setTotalItems,
  setCurrentPage,
  triggerChildRender,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    refundPaymentDetails: {
      refundPaymentsData,
      isGetRefundPaymentsLoading,
      isGetRefundPaymentsErr,
      getRefundPaymentsErr,
    },
    getPaymentsDataByType,
  } = useContext(PaymentContext);

  const refundPayments = refundPaymentsData?.refunds || [];
  const paymentsHeaders = refundPaymentsData?.refundHeaders;

  useEffect(() => {
    /**
     * Here we pass only currentPage and itemsPerPage, as on tab change rest
     * all filters will get reset so in the context it takes default values, hence
     * we do not need the useEffect in PaymentTabs.jsx file. */

    getPaymentsDataByType('refund', {
      currentPage,
      itemsPerPage,
      searchId,
      fromDate,
      toDate,
      filterKeys,
    });
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    setTotalItems(refundPaymentsData?.numberOfRefunds);
  }, [isGetRefundPaymentsLoading]);

  useEffect(() => {
    if (isGetRefundPaymentsErr) {
      enqueueSnackbar(
        `Error : ${getRefundPaymentsErr?.response?.data?.error?.message}`,
        {
          variant: 'error',
        },
      );
    }
  }, [getRefundPaymentsErr, refundPaymentsData]);

  const handleRowClick = (index) => {
    setUserType(refundPayments[index]?.userType);
    navigate(
      `/payments/${refundPayments[index]?._id}?userType=${refundPayments[index]?.userType}&paymentType=refund`, //paymentType for refund payment details on payment details page
    );
  };

  const createData = (paymentDetails) => {
    return paymentsHeaders.map((item) => paymentDetails[item.key]);
  };

  return (
    <DisplayTable
      tableId={'refundPayments'}
      rows={refundPayments?.map(createData)}
      headers={paymentsHeaders?.map((item) => item.value)}
      headersType={paymentsHeaders?.map((item) => item.type)}
      showActionsPanel={false}
      onClickFn={handleRowClick}
      arrBtn={arrBtn}
      actionIndex={actionIndex}
      setActionIndex={setActionIndex}
      actionOpen={actionOpen}
      setActionOpen={setActionOpen}
      setUserType={setUserType}
      tableData={refundPayments}
      customProps={{ paymentType: 'refund' }}
    />
  );
};

export default PaymentsRefundOrders;
