import React, { lazy, Suspense, useEffect, useState } from 'react';
import PaymentsIncomingOrders from './PaymentsIncomingOrders';
import PaymentsRefundOrders from './PaymentsRefundOrders';
import styleComponents from '../../style/pageStyle';
import ICONS from '../../assets/icons';
import { PAYMENT_TAB_HEADERS } from '../../constants';
import BoxLoader from '../common/BoxLoader';
import { Skeleton, Tab, Tabs } from '@mui/material';
import styled from 'styled-components';
const Pagination = lazy(
  () => import('../../components/atom/tableComponents/Pagination'),
);
const { Wrapper, Top, Bottom, AnimatedBox, Details, TopPageWrap } =
  styleComponents();

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 80vh;
`;
const PaymentsTabs = ({
  searchId,
  fromDate,
  toDate,
  setTotalItems,
  setSearchID,
  setFromDate,
  setToDate,
  totalItems,
  filterKeys,
  setTotalFiltersCount,
  setFilterKeys,
  clearFilters,
}) => {
  const [actionIndex, setActionIndex] = useState('');
  const [actionOpen, setActionOpen] = useState(false);
  const [userType, setUserType] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedTab, setSelectedTab] = useState(0);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [openDropdownBottom, setOpenDropdownBottom] = useState(false);
  /* const [triggerChildRender, setTriggerChildRender] = useState(false); */

  useEffect(() => {
    setFromDate(null);
    setToDate(null);
    setSearchID('');
    setTotalFiltersCount(0);
    setFilterKeys('');
    setCurrentPage(1);
    setItemsPerPage(10);
    clearFilters();
  }, [selectedTab]);

  /**
   * This useEffect listens to change in filters and change a state which
   * causes re render in the subsequent tabs component and this state is further
   * listened by useEffect in tabs component to get updated value of filters.
   * This is used due to default useEffect behaviour of running the effect in child
   * first and parent later, hence when the selectedTab is changed, the tabs component
   * gets rerendered with stale values of filters, and after it, the parent's effect is
   * triggered and all filters are updated.
   * Hence, we use this with combination of queryKeys to get desired results.
   * It has a drawback, that it makes 2 API calls, one with stale values and 1 with new
   * values of filters.
   */
  /* useEffect(() => {
    setTriggerChildRender(!triggerChildRender);
  }, [
    currentPage,
    itemsPerPage,
  ]);  All filters which are dependency for fetching on tab change to be included in deps array  */

  const tabHeaders = PAYMENT_TAB_HEADERS;

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSearchID('');
    setFromDate(null);
    setToDate(null);
  };

  const onShowSizeChange = (pageSize) => {
    if (itemsPerPage !== pageSize) {
      setCurrentPage(1);
      setItemsPerPage(pageSize);
    }
  };

  const handleDropdown = () => {
    setOpenDropdown(!openDropdown);
    setOpenDropdownBottom(!openDropdownBottom);
  };

  const handleDropdownBottom = () => {
    setOpenDropdownBottom(!openDropdownBottom);
    setOpenDropdown(!openDropdown);
  };

  const arrBtn = [
    {
      text: 'Edit',
      icon: ICONS.PENCIL,
      active: true,
      isVisible: true,
      color: '#586276',
      onClick: (e) => e.stopPropagation(),
    },
    {
      text: 'View Details',
      icon: ICONS.EYE,
      active: true,
      isVisible: true,
      color: '#586276',
      onClick: (e) => e.stopPropagation(),
    },
  ];

  const tabComponents = {
    0: (
      <PaymentsIncomingOrders
        currentPage={currentPage}
        /* triggerChildRender={triggerChildRender} */
        itemsPerPage={itemsPerPage}
        arrBtn={arrBtn}
        actionIndex={actionIndex}
        fromDate={fromDate}
        toDate={toDate}
        setTotalItems={setTotalItems}
        setActionIndex={setActionIndex}
        actionOpen={actionOpen}
        setActionOpen={setActionOpen}
        setUserType={setUserType}
        userType={userType}
        searchId={searchId}
        filterKeys={filterKeys}
      />
    ),
    1: (
      <PaymentsRefundOrders
        /* triggerChildRender={triggerChildRender} */
        selectedTab={selectedTab}
        searchId={searchId}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        fromDate={fromDate}
        setCurrentPage={setCurrentPage}
        toDate={toDate}
        arrBtn={arrBtn}
        actionIndex={actionIndex}
        setTotalItems={setTotalItems}
        setActionIndex={setActionIndex}
        actionOpen={actionOpen}
        setActionOpen={setActionOpen}
        setUserType={setUserType}
        userType={userType}
        filterKeys={filterKeys}
      />
    ),
  };

  return (
    <FlexContainer>
      <Top>
        <TopPageWrap>
          <Pagination
            isBackground={false}
            onShowSizeChange={onShowSizeChange}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalItems={totalItems}
            setTotalItems={setTotalItems}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            arrowBg={'#fff'}
            isFlexColumn={true}
            isBottom={false}
            setOpenDropdown={setOpenDropdown}
            openDropdown={openDropdown}
            handleDropdown={handleDropdown}
          />
        </TopPageWrap>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          textColor="#141482"
          TabIndicatorProps={{
            style: {
              backgroundColor: '#141482',
            },
          }}
          sx={{
            borderBottom: 1,
            borderBottomColor: '#CDD4DF',
          }}
        >
          {tabHeaders.map((item, idx) => {
            return (
              <Tab
                key={item}
                label={item}
                value={idx}
                sx={{
                  textTransform: 'none',
                  fontFamily: 'Poppins',
                  fontSize: '18px',
                  fontWeight: '500',
                  padding: '0px',
                  marginRight: '50px',
                  marginLeft: idx === 0 ? '20px' : '0px',
                  color: selectedTab === idx ? '#141482' : '#677995',
                }}
              />
            );
          })}
        </Tabs>
        <Suspense fallback={<BoxLoader size={5} />}>
          <Details>{tabComponents[selectedTab]}</Details>
        </Suspense>
      </Top>
      <Bottom>
        <Suspense
          fallback={
            <AnimatedBox>
              {[1].map((_, idx) => (
                <Skeleton animation="wave" height={70} key={idx} />
              ))}
            </AnimatedBox>
          }
        >
          <Pagination
            onShowSizeChange={onShowSizeChange}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalItems={totalItems}
            setTotalItems={setTotalItems}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            arrowBg={'#ebeff6'}
            isFlexColumn={false}
            isBottom={true}
            setOpenDropdown={setOpenDropdownBottom}
            openDropdown={openDropdownBottom}
            handleDropdown={handleDropdownBottom}
          />
        </Suspense>
      </Bottom>
    </FlexContainer>
  );
};

export default PaymentsTabs;
