import React, { useContext, useEffect } from 'react';
import DisplayTable from '../DisplayTable';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { PaymentContext } from '../../context/PaymentContext';

const PaymentsIncomingOrders = ({
  arrBtn,
  actionIndex,
  setActionIndex,
  actionOpen,
  setActionOpen,
  setUserType,
  currentPage,
  setTotalItems,
  itemsPerPage,
  filterKeys,
  searchId,
  toDate,
  fromDate,
}) => {
  const {
    incomingPaymentDetails: {
      allPaymentsData,
      isGetAllPaymentsLoading,
      isGetAllPaymentsErr,
      getAllPaymentsErr,
    },
    getPaymentsDataByType,
  } = useContext(PaymentContext);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const paymentsHeaders = allPaymentsData?.headers || [];
  const incomingPayments = allPaymentsData?.payment || [];

  useEffect(() => {
    /**
     * Here we pass only currentPage and itemsPerPage, as on tab change rest
     * all filters will get reset so in the context it takes default values, hence
     * we do not need the useEffect in PaymentTabs.jsx file.
     * TODO: One extra API call is made for stale states, need to optimise that
     * */

    getPaymentsDataByType('incoming', {
      currentPage,
      itemsPerPage,
      filterKeys,
      searchId,
      fromDate,
      toDate,
    });
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    setTotalItems(allPaymentsData?.totalPayments || 0);
  }, [allPaymentsData]);

  useEffect(() => {
    if (isGetAllPaymentsErr) {
      enqueueSnackbar(
        `Error : ${getAllPaymentsErr?.response?.data?.error?.message}`,
        {
          variant: 'error',
        },
      );
    }
  }, [isGetAllPaymentsErr, allPaymentsData]);

  const handleRowClick = (index) => {
    setUserType(incomingPayments[index]?.userType);
    navigate(
      `/payments/${incomingPayments[index]?._id}?userType=${incomingPayments[index]?.userType}&paymentType=${'incoming'}`, //paymentType for incoming payment details
    );
  };

  const createData = (paymentDetails) => {
    return paymentsHeaders.map((item) => paymentDetails[item.key]);
  };

  return (
    <DisplayTable
      tableId={'incomingPayments'}
      rows={incomingPayments?.map(createData)}
      headers={paymentsHeaders?.map((item) => item.value)}
      headersType={paymentsHeaders?.map((item) => item.type)}
      showActionsPanel={false}
      onClickFn={handleRowClick}
      arrBtn={arrBtn}
      actionIndex={actionIndex}
      setActionIndex={setActionIndex}
      actionOpen={actionOpen}
      setActionOpen={setActionOpen}
      setUserType={setUserType}
      tableData={incomingPayments}
      customProps={{ paymentType: 'incoming' }}
    />
  );
};

export default PaymentsIncomingOrders;
