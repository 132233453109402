import React, { useState } from 'react';
import styleComponents from '../../style/pageStyle';
import styled from 'styled-components';
import ICONS from '../../assets/icons';
import IMAGES from '../../assets/images';
import { useGetEarnings } from '../../apis/queryHooks';
import { daysList, daysListMap } from '../../constants/employer';
import DrawerInput from '../common/DrawerInput';
import { CircularProgress } from '@mui/material';
import { zIndexValues } from '../../style';

const FlexContainer = styled.div`
  font-family: Poppins;
  width: 100%;
  display: flex;
  flex-direction: ${(props) =>
    props.$flexDirection ? props.$flexDirection : 'row'};
  align-items: ${(props) =>
    props.$alignItems ? props.$alignItems : 'flex-start'};
  justify-content: ${(props) =>
    props.$justifyContent ? props.$justifyContent : 'flex-start'};
  margin-top: ${(props) => (props.$marginTop ? props.$marginTop : '0px')};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 61px);
  position: fixed;
  top: 61px;
  z-index: 15;
  object-fit: cover;
`;

const ContainerImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 16;
`;

const AbsFlexContainer = styled(FlexContainer)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndexValues.CALCULATE_EARNING_CONTAINER};
  widht: 100%;
  height: 100%;
`;

const P = styled.p`
  white-space: nowrap;
  color: ${(props) => props?.$color};
  font-family: Poppins;
  font-size: ${(props) => (props?.$fontSize ? props.$fontSize : '14px')};
  font-style: normal;
  font-weight: ${(props) => props?.$fontWeight};
  line-height: ${(props) => props?.$lineHeight};
  margin-top: ${(props) => props?.$marginTop};
`;
const H1 = styled.h1`
  white-space: nowrap;
  color: ${(props) => props?.$color};
  font-family: Poppins;
  font-size: ${(props) => (props?.$fontSize ? props.$fontSize : '14px')};
  font-style: normal;
  font-weight: ${(props) => props?.$fontWeight};
  line-height: ${(props) => props?.$lineHeight};
`;

const NumberInWordstext = styled.p`
  white-space: nowrap;
  color: #51e5ff;
  font-family: Gelasio !important;
  font-size: ${(props) => (props?.$fontSize ? props.$fontSize : '14px')};
  font-style: italic;
  font-weight: ${(props) => props?.$fontWeight};
  line-height: ${(props) => props?.$lineHeight};
`;

const Img = styled.img`
  width: ${(props) => props.width || '56px'};
  height: ${(props) => props.height || '56px'};
  cursor: pointer;
`;

const StyledText = styled.div`
  background: linear-gradient(90deg, #d499ff 0%, #4de7ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: ${(props) => props.$fontSize ?? '150px'};
  font-style: normal;
  font-weight: 700;
  line-height: ${(props) => props.$lineHeight ?? '177.5px'};
  margin-left: 28px;
`;

const LeftArrow = styled.div`
  position: absolute;
  top: 40px;
  left: 80px;
  z-index: ${zIndexValues.BACK_ARROW};
`;

const NextArrow = styled.div`
  position: absolute;
  bottom: 50px;
  right: 40px;
  z-index: 17;
`;

const CalculateEarnings = ({
  createAccObj,
  handleOpenCreateAccountModal = () => {},
  setIsCalculateEarningsVisible = () => {},
  setOpenCreateAccDrawer,
  openCreateAccDrawer,
}) => {
  const { companySize, companyName } = createAccObj;
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState(daysList[0]);
  const days = daysListMap[selectedDays];
  const payload = { companySize, days };
  const {
    data: allEarningsData,
    isLoading: allEarningsLoading,
    isFetching: allEarningsFetching,
  } = useGetEarnings(payload);

  const handleLeftArrow = () => {
    setIsCalculateEarningsVisible(false);
    setOpenCreateAccDrawer(!openCreateAccDrawer);
  };

  const handleCreateAccDrawer = () => {
    handleOpenCreateAccountModal(createAccObj);
  };

  const handleCategorySelect = (item) => {
    setSelectedDays(item);
    setCategoryOpen(!categoryOpen);
  };

  return (
    <Container>
      <ContainerImage src={IMAGES.CALCULATE_EARNINGS} />
      <LeftArrow>
        <Img src={ICONS.EARNING_BACK} onClick={() => handleLeftArrow()} />
      </LeftArrow>
      <AbsFlexContainer
        $flexDirection="column"
        $alignItems="center"
        $justifyContent="center"
      >
        <P
          $color="#FFC107"
          $fontSize="19px"
          $fontWeight="600"
          $lineHeight={'29px'}
        >
          Potential Earnings
        </P>
        <H1
          $color="#FFFFFF"
          $fontSize="38px"
          $fontWeight="600"
          $lineHeight={'38px'}
        >
          {companyName}
        </H1>
        {!(allEarningsLoading || allEarningsFetching) ? (
          <>
            <FlexContainer
              $justifyContent="center"
              $alignItems="center"
              $marginTop="72px"
            >
              <Img width="52px" height="178px" src={ICONS?.RUPEE} />
              <StyledText>{allEarningsData?.TotalEarning}</StyledText>
              <StyledText $fontSize={'65px'}>/-</StyledText>
            </FlexContainer>
            <NumberInWordstext
              $color="#fff"
              $fontSize="24px"
              $fontWeight="400"
              $lineHeight={'29px'}
            >
              {allEarningsData?.numberInWords}
            </NumberInWordstext>
            <DrawerInput
              $background="red"
              border="none"
              isManadatory={false}
              fieldType={'days'}
              fieldValue={`In ${selectedDays}`}
              handleDropDownSelect={handleCategorySelect}
              dropDownOpen={categoryOpen}
              handleDropDownOpen={setCategoryOpen}
              dropDownList={daysList}
            />
          </>
        ) : (
          <CircularProgress size={60} />
        )}
      </AbsFlexContainer>
      <NextArrow onClick={() => handleCreateAccDrawer()}>
        <Img
          src={ICONS.NEXT_BUTTON}
          alt="leftArrowBlack"
          width={'149px'}
          height={'56px'}
        />
      </NextArrow>
    </Container>
  );
};

export default CalculateEarnings;
