import { Skeleton } from '@mui/material';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import CustomCTA from '../components/CustomCTA';
import styleComponents from '../style/pageStyle';
import { getNestedProperty } from '../utils/helper';
import ICONS from '../assets/icons';
import usePermission from '../hooks/usePermission';
import { PAYOUT_PERMISSIONS } from '../constants/permissions';
import { useGetAllPayouts } from '../apis/queryHooks';
const Pagination = lazy(
  () => import('../components/atom/tableComponents/Pagination'),
);
const DisplayTable = lazy(() => import('../components/DisplayTable'));
const FilterPayoutsDrawer = lazy(
  () => import('../components/payouts/FilterPayoutsDrawer'),
);
const PayoutRequestDrawer = lazy(
  () => import('../components/payouts/PayoutRequestDrawer'),
);

const {
  Wrapper,
  Top,
  Bottom,
  HeaderWrap,
  Header,
  HeaderTitle,
  HeaderDesc,
  SearchDiv,
  SearchBox,
  AnimatedBox,
  Details,
  TopPageWrap,
  TableDiv,
} = styleComponents();

const PayoutRequests = () => {
  const { hasPermission } = usePermission();

  const [totalPayouts, setTotalPayouts] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [openDropDownBottom, setOpenDropDownBottom] = useState(false);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [openPayoutDrawer, setOpenPayoutDrawer] = useState(false);
  const [totalFiltersCount, setTotalFiltersCount] = useState(0);
  const [filterKeys, setFilterKeys] = useState('');
  const [actionIndex, setActionIndex] = useState('');
  const [actionOpen, setActionOpen] = useState(false);
  const [payoutObj, setPayoutObj] = useState({});
  const [payoutCheckboxes, setPayoutCheckBoxes] = useState([
    { key: 'VERIFIED', value: 'Verified', checked: false },
    { key: 'PENDING', value: 'Pending', checked: false },
    { key: 'REJECTED', value: 'Rejected', checked: false },
  ]);

  const { data: allPayouts, refetch: refetchAllPayouts } = useGetAllPayouts({
    itemsPerPage: itemsPerPage,
    currentPage: currentPage,
    filterKeys: filterKeys,
  });

  useEffect(() => {
    if (allPayouts?.data) setTotalPayouts(allPayouts?.count);
  }, [allPayouts]);

  const headerValues = allPayouts?.headers?.map((item) => item.value) || [];
  const headerTypes = allPayouts?.headers?.map((item) => item.type) || [];

  function createData(payoutDetails) {
    const headerKeys = Array.from(allPayouts?.headers.map((item) => item.key));
    return headerKeys.map((item) => {
      const itemKey = item.replace(/['"]+/g, '');
      return getNestedProperty(payoutDetails, itemKey);
    });
  }

  const rows = allPayouts?.data?.map((item) => createData(item)) || [];

  const onShowSizeChange = (pageSize) => {
    if (itemsPerPage !== pageSize) {
      setCurrentPage(1);
      setItemsPerPage(pageSize);
    }
  };

  const handleDropDownBottom = () => {
    setOpenDropDownBottom(!openDropDownBottom);
  };

  const clearFilters = () => {
    setPayoutCheckBoxes((prevCheckboxes) => {
      return prevCheckboxes.map((checkbox) => {
        return { ...checkbox, checked: false };
      });
    });
  };

  const handleViewPayoutRequest = (e) => {
    e.stopPropagation();
    setPayoutObj(allPayouts?.data[actionIndex]);
    setOpenPayoutDrawer(true);
  };

  const handleRowClick = (index) => {
    setPayoutObj(allPayouts?.data[index]);
    setOpenPayoutDrawer(true);
  };

  const arrBtn = [
    {
      text: 'View',
      icon: ICONS.EYE,
      active: true,
      isVisible: true,
      color: '#000',
      onClick: handleViewPayoutRequest,
      permission: PAYOUT_PERMISSIONS?.VIEW_PAYOUT_DETAILS,
    },
  ];

  return (
    <Wrapper>
      <Top>
        <HeaderWrap>
          <Header>
            <HeaderTitle>Payout Requests</HeaderTitle>
          </Header>
        </HeaderWrap>
        <Suspense fallback={<div></div>}>
          <SearchDiv>
            <SearchBox></SearchBox>
            <CustomCTA
              onClick={() => setOpenFilterDrawer(true)}
              url={ICONS.FILTER}
              title={`Filter (${totalFiltersCount ?? ''})`}
              showIcon={true}
              bgColor={'#677995'}
              color={'#FFF'}
              border={'none'}
              fontSize={'12px'}
              gap={'12px'}
            />
          </SearchDiv>
        </Suspense>

        <Suspense
          fallback={
            <AnimatedBox>
              {[1, 2, 3, 4, 5].map((item, idx) => {
                return <Skeleton animation="wave" height={70} key={idx} />;
              })}
            </AnimatedBox>
          }
        >
          <Details>
            <TableDiv>
              <DisplayTable
                tableId={'customersTable'}
                highlightRow={true}
                rows={rows}
                headers={headerValues}
                showActionsPanel={true}
                headersType={headerTypes}
                tableWidth={'95%'}
                arrBtn={arrBtn}
                actionIndex={actionIndex}
                setActionIndex={setActionIndex}
                actionOpen={actionOpen}
                setActionOpen={setActionOpen}
                onClickFn={handleRowClick}
              />
            </TableDiv>
          </Details>
        </Suspense>
      </Top>
      <Bottom>
        <Suspense fallback={<div></div>}>
          <Pagination
            onShowSizeChange={onShowSizeChange}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalItems={totalPayouts}
            setTotalItems={setTotalPayouts}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            arrowBg={'#fff'}
            isFlexColumn={false}
            isBottom={true}
            setOpenDropdown={setOpenDropDownBottom}
            openDropdown={openDropDownBottom}
            handleDropdown={handleDropDownBottom}
          />
          <FilterPayoutsDrawer
            open={openFilterDrawer}
            toggleDrawer={setOpenFilterDrawer}
            totalFiltersCount={totalFiltersCount}
            setTotalFiltersCount={setTotalFiltersCount}
            setFilterKeys={setFilterKeys}
            paymentCheckboxes={payoutCheckboxes}
            setPaymentCheckBoxes={setPayoutCheckBoxes}
            clearFilters={clearFilters}
          />
          <PayoutRequestDrawer
            open={openPayoutDrawer}
            toggleDrawer={setOpenPayoutDrawer}
            payoutObj={payoutObj}
            refetchAllPayouts={refetchAllPayouts}
          />
        </Suspense>
      </Bottom>
    </Wrapper>
  );
};

export default PayoutRequests;
